import React, { useEffect, useState } from 'react';
import { default as pdfImage } from "../assets/PDF Icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const SideDrawer = ({ chatsFetched, convoId, selectedMessageIndex, isOpen, toggleDrawer, selectedSearchFiles, handleSearchFileID, selectedTab }) => {
  const [messageFiles, setMessageFiles] = useState([]);
  const [convoType, setconvoType] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if(chatsFetched){
      for(const chat of chatsFetched) {
          if(chat?.id === convoId){
              setMessageFiles(chat?.conversation_history[selectedIndex][3]);
          }
      }
    }
  }, [selectedTab])
  
  useEffect(() => {
    setSelectedIndex(selectedMessageIndex)
    const timer = setTimeout(() => {
      if(chatsFetched){
        for (const chat of chatsFetched) {
          if (chat?.id === convoId) {
            setMessageFiles(chat?.conversation_history[selectedMessageIndex][3]);
          }
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedMessageIndex, convoId]);
  
  useEffect(() => {
    setMessageFiles(selectedSearchFiles?.items);
    setconvoType(selectedSearchFiles?.type);
    if(selectedSearchFiles?.items?.length === 0){
        handleSearchFileID(0)
    }
  }, [selectedSearchFiles])

  const handleOpenInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const getSearchFileID = (id) =>{
    handleSearchFileID(id)
  }


  return (
    <div className={`side-drawer ${isOpen ? 'open' : ''}`}>
      <button className={`toggle-button ${isOpen ? 'isOpen' : ''}`} onClick={toggleDrawer}> 
        <p style={{paddingRight: '1rem'}}>
            {messageFiles?.length > 0 && (
            <span className='count-badge'>{messageFiles?.length}</span>
            )}
            {selectedTab === 'Search' ? 'Selected Files' : 'Source Files'}
        </p>
        <div style={{position: 'relative', margin: 'auto 0'}}><p className={`arrow ${isOpen ? 'isOpen' : ''}`}></p></div>
      </button>
      {isOpen && (
        <div className="drawer-content">
          {Array.isArray(messageFiles) && messageFiles.length > 0 ? (
                selectedTab === 'Search' ? (
                    <p key="search-selected">Selected files:</p>
                ):(
                    <p key="search-source">Source files:</p>
                )
            ) : ( 
                selectedTab === 'Search' ? (
                    <p key="empty-selected">Selected files will appear here.</p>
                ):(
                    <p key="empty-source">Source files will appear here.</p>
                )
            )}
          <div className='src-shadow-box'>  
            {messageFiles?.map((file, index) => (
                <div className='drawer-card'>
                    <img src={pdfImage} style={{ height: '20px', width: '15px' }} alt="More" />
                    <p style={{fontSize: '12px', cursor: 'pointer'}}  key={file?.id || `file-${index}`} onClick={() => handleOpenInNewTab(file["source"])}>{file.name}</p>
                    {selectedTab === 'Search' && (
                        <FontAwesomeIcon
                            style={{margin:'auto', position:'relative'}}
                            icon={faClose}
                            onClick={() => {
                                getSearchFileID(file?.id)
                            }}
                        />
                    )}
                </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideDrawer;
