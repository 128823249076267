import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLoader from './loader.css';
import { FaArrowLeft } from 'react-icons/fa';
import ApiService from '../ApiService';
import AssistAction from '../components/AssistAction';
import { default as pdfImage } from "../assets/PDF Icon.png";

const SearchNav = ({ project_id = 0, callback ,callbackInitiateChat, onSelectedItemsChange, searchFilesId}) => {
  const [areDialogsOpen, setAreDialogsOpen] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showSourceId, setShowSourceId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showExistingDialogs, setShowExistingDialogs] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [assetId, setAssetId] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [paginationNumber, setPaginationNumber] = useState(0);
  const [enterPress, setEnterPress] = useState(true);
  const [showAssist, setShowAssist] = useState(false);
  const [assetdata, setAssetdata] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [FileId, setFileId] = useState(0);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleOpenInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const limit = 10;

  const handleLoadMore = () => {
    setEnterPress(false);
    setPaginationNumber(paginationNumber + limit);
    fetchSearchResults(inputValue);
  };

  useEffect(() => {
    console.log('Enter press', enterPress);
  }, [enterPress]);

  useEffect(() => {
    console.log("Updated searchResults:", searchResults);
    setCheckboxes({});
  }, [searchResults]);

  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;
    setMessages([...messages, { sender: 'user', text: newMessage }]);
    setNewMessage('');
  };


  useEffect(() => {
    if (showSourceId !== null) {
      console.log(`showSourceId updated:`, showSourceId);
      // Perform any action you need after the state is updated
    }
  }, [showSourceId]);


  const fetchResponse = async () => {
    try {
      const response = await ApiService.get(`/assistactions?project_id=${project_id.toString()}`);
      if (JSON.stringify(response.data) !== JSON.stringify(assetdata)) {
        setAssetdata(response.data);
      }
      console.log("API response data:", assetdata);
    } catch (error) {
      toast.error("Error fetching data");
      console.error("API call error:", error);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowSuggestions(value.length > 0);
  };

  const handleSelectSuggestion = async (suggestion) => {
    setInputValue(suggestion);
    setShowSuggestions(false);
    setAreDialogsOpen(true);
    setShowExistingDialogs(true);
    await fetchSearchResults(suggestion);
  };

  const fetchSearchResults = async (question) => {
    setLoading(true);

    try {
      const response = await ApiService.post(`/search?skip=${paginationNumber}&limit=${limit}&project_id=${project_id.toString()}&question=${question}`);

      console.log('response data ===> ', response);
      const searchResultsVector = Array.isArray(searchResults['files_by_vector']) ? (!enterPress) ? searchResults['files_by_vector'] : [] : [];
      const responseVector = Array.isArray(response.data['files_by_vector']) ? response.data['files_by_vector'] : [];

      const searchResultsNames = Array.isArray(searchResults['files_by_name']) ? (!enterPress) ? searchResults['files_by_name'] : [] : [];
      const responseNames = Array.isArray(response.data['files_by_name']) ? response.data['files_by_name'] : [];
      const combinedFilesByVector = Array.from(new Map([...searchResultsVector, ...responseVector].map(item => [item.id, item])).values());

      const searchResults2 = {
        files_by_vector_count: combinedFilesByVector.length,
        files_by_vector: combinedFilesByVector,
        files_by_name_count: searchResultsNames.length,
        files_by_name: responseNames,
        skip: paginationNumber + limit,
        limit: limit,
        project_id: searchResults.project_id,
        message: "Successfully merged search results",
        status: "success"
      };
      setSearchResults(searchResults2);
    } catch (error) {
      toast.error('Failed to fetch search results', {
        duration: 3000,
        position: 'top-center',
        style: {
          background: '#333',
          color: '#fff',
        },
      });
    } finally {
      setLoading(false);
    }
  };


  const handleCloseBox = () => {
    setAreDialogsOpen(true);
    setShowNewModal(false);
  };

  const handleCheckboxChange = (id, result) => {
    const updatedCheckboxes = {
      ...checkboxes,
      [id]: !checkboxes[id],
    };
  
    setCheckboxes(updatedCheckboxes);
  
    if (!checkboxes[id]) {
      setSelectedItems((prevItems) => [...prevItems, result]);
    } else {
      setSelectedItems((prevItems) =>
        prevItems.filter((item) => item.id !== id)
      );
    }
  };

  useEffect(() => {
    if (typeof searchFilesId !== "number") return;

    const isItemInSelectedItems = selectedItems.some(
      (item) => item.id === searchFilesId
    );

    const updatedSelectedItems = selectedItems.filter(
      (item) => item.id !== searchFilesId
    );

    const updatedCheckboxes = { ...checkboxes };
    if (isItemInSelectedItems) {
      updatedCheckboxes[searchFilesId] = false;
    }

    setSelectedItems(updatedSelectedItems);
    setCheckboxes(updatedCheckboxes);
  }, [searchFilesId]);

  useEffect(() => {
    onSelectedItemsChange(selectedItems);
  }, [selectedItems]);
  
  const handleSearch = () => {
    setEnterPress(true);
    setPaginationNumber(0);
    setSearchResults([]);
    setLoading(true);
    handleSelectSuggestion(inputValue);
  };

  const openDialogs = () => {
    const anyCheckboxSelected = Object.values(checkboxes).some(
      (checked) => checked
    );

    if (!anyCheckboxSelected) {
      toast.error('Please Select Checkbox.', {
        duration: 3000,
        position: 'top-center',
        style: {
          background: '#333',
          color: '#fff',
        },
      });
    } else {
      fetchResponse(project_id);
      setShowNewModal(true);
    }
  };

  return (
    <div className="search-nav">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="responsive-container w-full">
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          placeholder="Type to search..."
          className="w-full p-4 border border-black-300 rounded"
        />
        <button
          type="button"
          onClick={handleSearch}
          className="absolute inset-y-0 right-3 flex items-center p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.9 14.32a8 8 0 111.414-1.414l4.297 4.296a1 1 0 01-1.414 1.415l-4.297-4.297zM8 14a6 6 0 100-12 6 6 0 000 12z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
            <div className="spinner"></div>
          </div>
        )}
      </div>


      {!loading && areDialogsOpen && showExistingDialogs && (
        <div className="dialogs-container flex flex-col items-center mt-4">
          {searchResults['files_by_name']?.length > 0 && (
            <div className="filename-dialog accordion bg-white rounded-md w-full max-w-lg mb-4 shadow-lg border-2 border-black">
            <div 
              className={`accordion-header flex justify-between items-center cursor-pointer bg-primary search-headers ${!accordionOpen ? 'accordian-radius' : ''}`}
              onClick={() => setAccordionOpen(!accordionOpen)}
            >
              <h4 className="text-xl font-bold text-left text-primary">
              {searchResults['files_by_name'] ? searchResults['files_by_name'].length : 0} Files matched the search 
              </h4>
              <span className={`accordion-icon ${accordionOpen ? 'rotate-180' : ''}`}>
                {/* <span className='accordion-arrow'></span> */}
              </span>
            </div>
            {accordionOpen && (
              <div className="accordion-body">
                <div className="text-container">
                  {searchResults['files_by_name'] != null && searchResults['files_by_name'].length > 0 ? (
                    searchResults['files_by_name'].map((result, index) => (
                      <div style={{ display: 'flex' }} key={index}>
                        <div style={{ width: '5%', display: 'flex', margin: 'auto' }}>
                          <span className="filename-icon"></span>
                        </div>
                        <div style={{ width: '95%' }}>
                          <p
                            className="checkbox-container"
                            style={{ margin: "12px 0.25rem", cursor: "pointer", color: "black" }}
                            onClick={() => handleOpenInNewTab(result["source"])}
                          >
                            {result['name']}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p style={{ margin: "12px", paddingLeft: "14px" }}>No results found.</p>
                  )}
                </div>
                {searchResults['files_by_name'] != null && searchResults['files_by_name'].length > 0 && (
                  <div className="load-more-center">
                    <button 
                      onClick={handleLoadMore} 
                      className="load-more-btn mt-4 text-center text-blue-500 hover:underline"
                    >
                      Load more...
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>          
          )}

          <div style={{ overflow: 'hidden' }} className="semantic-search-dialog bg-white p-6 rounded-md w-full max-w-lg shadow-lg border-2 border-black">
            <div>
              <h4 className="text-xl font-semibold mb-2 text-left bg-primary search-headers">
                Semantic Search ({searchResults['files_by_vector'] ? searchResults['files_by_vector'].length : 0})
              </h4>
            </div>
            <div className="blue-line"></div>
            <div style={{overflow: 'auto', height:'450px'}}>
              <div className=" mt-4">
                {searchResults['files_by_vector'] != null && searchResults['files_by_vector'].length > 0 ? (
                  searchResults['files_by_vector'].map((result, index) => {
                    const key = result.id;
                    return (
                      <div key={index}>
                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex", width: "10%" }}><img src={pdfImage} style={{ height: '35px', width: '25px', margin:'auto' }} alt="More" /></div>
                          <div style={{ width: "80%" }}>
                            <h5 className="font-medium text-lg modal-header-left-align cursor-pointer" onClick={() => handleOpenInNewTab(result["source"])} >
                              {result["name"]}
                            </h5>
                            <p style={{ fontSize: '14px' }}>{result["preview"]}</p>
                          </div>
                          <div style={{ display: "flex", width: "10%" }}>
                            <input
                              type="checkbox"
                              style={{ margin:'auto' }}
                              checked={checkboxes[key] || false}
                              onChange={(e) => {
                                handleCheckboxChange(key, result);
                                if (e.target.checked) {
                                  console.log(`Checked: ${result.id}`);
                                  setShowSourceId(prevState => [...prevState, result.id]);
    
    
    
                                } else {
                                  console.log("Checkbox is unchecked");
                                  console.log(`Unchecked: ${result.id}`);
                                  console.log(`showSourceId`, showSourceId);
                                  setShowSourceId(prevState => prevState.filter(id => id !== result.id));
    
                                }
                              }}
                              aria-label={`Checkbox for ${result["name"]}`}
                            />
                          </div>
                        </div>
                        {index !== searchResults['files_by_vector'].length - 1 && (
                          <div className="wide-separator my-4"></div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No results found.</p>
                )}

                <div className="load-more-center">
                  {searchResults['files_by_vector'] != null && searchResults['files_by_vector'].length > 0 && (
                    <button className="load-more-btn mt-4 text-center text-blue-500 hover:underline" onClick={handleLoadMore}>
                      Load more...

                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*ChatBox*/}


          <button
            onClick={openDialogs}
            className="mt-6 -black-500 hover:underline"
          >
            <svg className="floating-icon"
              size={100}
              style={{
                backgroundColor: 'rgb(59,130,246)',
                color: 'white',
                borderRadius: '50%',
                padding: '10px',
              }} width="56" height="56" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

              <g clip-path="url(#clip0_117_90)">
                <path d="M35.9997 16.8708C35.7828 13.3608 34.5435 9.99103 32.4345 7.1769C30.3256 4.36277 27.4392 2.2273 24.1313 1.03376C20.8234 -0.159788 17.2385 -0.359234 13.8186 0.460009C10.3987 1.27925 7.29325 3.08137 4.88517 5.64419C2.47708 8.20702 0.871601 11.4185 0.266653 14.8828C-0.338295 18.347 0.0837346 21.9125 1.48071 25.1398C2.87769 28.3671 5.18855 31.1151 8.1284 33.0449C11.0683 34.9747 14.5086 36.0021 18.0252 36.0003H28.4997C30.4883 35.9983 32.3948 35.2075 33.8008 33.8014C35.2069 32.3953 35.9978 30.4888 35.9997 28.5003V16.8708ZM32.9997 28.5003C32.9997 29.6937 32.5256 30.8383 31.6817 31.6822C30.8378 32.5262 29.6932 33.0003 28.4997 33.0003H18.0252C15.9086 32.9993 13.816 32.5522 11.8838 31.6882C9.95157 30.8242 8.22315 29.5626 6.81124 27.9858C5.3925 26.4097 4.32714 24.5485 3.68658 22.5269C3.04603 20.5054 2.84509 18.3703 3.09724 16.2648C3.49539 12.9437 4.98752 9.84955 7.3384 7.47015C9.68928 5.09076 12.7652 3.56143 16.0812 3.12326C16.7281 3.04219 17.3793 3.00111 18.0312 3.00026C21.527 2.99073 24.9144 4.21212 27.5997 6.45026C29.168 7.75368 30.4553 9.36199 31.3835 11.1777C32.3118 12.9934 32.8616 14.9787 32.9997 17.0133V28.5003Z" fill="white" />
                <path d="M11.9998 13.4998H17.9998C18.3976 13.4998 18.7791 13.3417 19.0604 13.0604C19.3417 12.7791 19.4998 12.3976 19.4998 11.9998C19.4998 11.6019 19.3417 11.2204 19.0604 10.9391C18.7791 10.6578 18.3976 10.4998 17.9998 10.4998H11.9998C11.6019 10.4998 11.2204 10.6578 10.9391 10.9391C10.6578 11.2204 10.4998 11.6019 10.4998 11.9998C10.4998 12.3976 10.6578 12.7791 10.9391 13.0604C11.2204 13.3417 11.6019 13.4998 11.9998 13.4998Z" fill="white" />
                <path d="M23.9998 16.5002H11.9998C11.6019 16.5002 11.2204 16.6583 10.9391 16.9396C10.6578 17.2209 10.4998 17.6024 10.4998 18.0003C10.4998 18.3981 10.6578 18.7796 10.9391 19.0609C11.2204 19.3422 11.6019 19.5003 11.9998 19.5003H23.9998C24.3976 19.5003 24.7791 19.3422 25.0604 19.0609C25.3417 18.7796 25.4998 18.3981 25.4998 18.0003C25.4998 17.6024 25.3417 17.2209 25.0604 16.9396C24.7791 16.6583 24.3976 16.5002 23.9998 16.5002Z" fill="white" />
                <path d="M23.9998 22.5H11.9998C11.6019 22.5 11.2204 22.658 10.9391 22.9393C10.6578 23.2206 10.4998 23.6022 10.4998 24C10.4998 24.3978 10.6578 24.7794 10.9391 25.0607C11.2204 25.342 11.6019 25.5 11.9998 25.5H23.9998C24.3976 25.5 24.7791 25.342 25.0604 25.0607C25.3417 24.7794 25.4998 24.3978 25.4998 24C25.4998 23.6022 25.3417 23.2206 25.0604 22.9393C24.7791 22.658 24.3976 22.5 23.9998 22.5Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_117_90">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>


          </button>
        </div>
      )}

      {showNewModal && (
        <AssistAction
          assetId={assetId}
          project_id={project_id}
          setAssetId={setAssetId}
          showNewModal={showNewModal}
          messages={messages}
          handleCloseSearchNav={handleCloseBox}
          openDialogs={openDialogs}
          isQuestionView={true}
          assetdata={assetdata}
          showSourceId={showSourceId}
          callback={callback}
          callbackInitiateChat={callbackInitiateChat}
        />
      )}
    </div>
  );
};

export default SearchNav;
