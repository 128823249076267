import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import ApiService from '../ApiService';
import { toast } from 'react-hot-toast';
import { Highlight, themes } from "prism-react-renderer";

const AssistAction = ({
  messages,
  handleCloseSearchNav,
  additionalData,
  project_id = 0,
  convoId,
  callback,
  callbackInitiateChat,
  isQuestionView = false,
  assetdata,
  showSourceId,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [selectedConvoId, setSelectedConvoId] = useState(convoId);
  const [chatMessages, setChatMessages] = useState(messages || []);
  const [loadingState, setLoadingState] = useState(false);
  const [showAssist, setShowAssist] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [isFirstInteraction, setIsFirstInteraction] = useState(true);
  const [isFirstInteractionLoading, setIsFirstInteractionLoading] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const chatBoxRef = useRef(null);
  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const handleShowAssistAction = async (actionId) => {
    setSelectedAssetId(actionId);
    setShowAssist(true);

    if (isFirstInteraction) {
      setIsFirstInteractionLoading(true);

      const formData = {
        "documents_ids": showSourceId,
        "assist_action_id": actionId,
        "conversation_id": selectedConvoId === 'NEW' ? null : selectedConvoId,
        "additional_data": additionalData ? additionalData : null,
        "question": null,
        "project_id": project_id,
      };

      console.log("Form Data for first interaction:", formData);

      try {
        setLoadingState(true);
        const response = await ApiService.post('/answer', formData);
        setLoadingState(false);

        if (response?.data?.answer) {
          console.log('Bot Response:', response);

          setSelectedConvoId(response?.data?.conversation_id);
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'bot', text: response.data.answer },
          ]);

        } else {
          throw new Error('Unexpected response structure');
        }
        if (callback) callback();

      } catch (error) {
        console.error('Error:', error);

        toast.error(
          error?.response?.data?.message || 'Unable to process the request. Please try again.',
          {
            style: {
              backgroundColor: '#e23650',
              color: '#ffffff',
            },
          }
        );
      } finally {
        setIsFirstInteraction(false);
        setIsFirstInteractionLoading(false);
      }
    }
  };
  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedAssetId) return;

    const formData = {
      "documents_ids": showSourceId,
      "conversation_id": selectedConvoId === 'NEW' ? null : selectedConvoId,
      "additional_data": additionalData ? additionalData : null,
      "question": newMessage,
      "project_id": project_id,
    };

    // Add the user's message to chatMessages
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'user', text: newMessage },
    ]);

    setNewMessage('');

    try {
      setLoadingState(true);

      const response = await ApiService.post('/answer', formData);
      setLoadingState(false);
      setIsFirstInteractionLoading(false);

      if (response?.data?.answer) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: response.data.answer },
        ]);
      } else {
        throw new Error('Unexpected response structure');
      }

      if (callback) callback();
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'Unable to process the request. Please try again.',
        {
          style: {
            backgroundColor: '#e23650',
            color: '#ffffff',
          },
        }
      );
      setLoadingState(false);
    }
  };
  const CopyToClipboard = (t) => {
    navigator.clipboard.writeText(t).then(() => {
    }).catch(err => {
    });
  };
  const insertCodeBlocks = (text) => {
    const regex = /```([\s\S]*?)```/g;
    let match;
    let currentIndex = 0;
    const elements = [];
    const processText = (line) => {
      const headingRegex = /^(#{1,6})\s+(.*)/;

      const boldItalicRegex = /(\*\*\*|___)(.*?)\1/g;
      const boldRegex = /(\*\*|__)(.*?)\1/g;
      const italicRegex = /(\*|_)(.*?)\1/g;

      const headingMatch = line.match(headingRegex);
      if (headingMatch) {
        const headingLevel = headingMatch[1].length; 
        const headingText = headingMatch[2];
        const formattedHeadingText = headingText
          .replace(boldItalicRegex, (_, __, match) => `<b><em>${match}</em></b>`)
          .replace(boldRegex, (_, __, match) => `<b>${match}</b>`)
          .replace(italicRegex, (_, __, match) => `<em>${match}</em>`);
        return `<h${headingLevel}>${formattedHeadingText}</h${headingLevel}>`;
      }

      line = line.replace(boldItalicRegex, (_, __, match) => `<b><em>${match}</em></b>`);

      line = line.replace(boldRegex, (_, __, match) => `<b>${match}</b>`);

      line = line.replace(italicRegex, (_, __, match) => `<em>${match}</em>`);

      return line;
    };


    while ((match = regex.exec(text)) !== null) {
      const beforeText = text.substring(currentIndex, match.index).split('\n').map((line, index) => (
        <React.Fragment key={`A${index}`}>
          <span>{line}</span>
          <br />
        </React.Fragment>
      ));
      elements.push(...beforeText);

      const x2 = match[1];
      const mType = x2.split('\n')[0];
      let mTypeCode = 'jsx';
      switch (mType.trim()) {
        case 'javascript':
          mTypeCode = 'js';
          break;
        case 'typescript':
          mTypeCode = 'tsx';
          break;
        case 'c++':
          mTypeCode = 'cpp';
          break;
        case 'php':
          mTypeCode = 'javascript';
          break;
        case 'asp':
          mTypeCode = 'javascript';
          break;
        case 'csharp':
          mTypeCode = 'cpp';
          break;
        default:
          mTypeCode = mType.toLowerCase();
          break;
      }
      const codeBlock = (
        <div className="code-block">
          <button
            onClick={() => CopyToClipboard(x2)}
            title="Copy Code"
            className="flex justify-end p-1 mr-0 ml-auto right-0 rounded text-xs text-gray-500 hover:bg-gray-500 hover:text-gray-100 duration-300"
          >
            <i className="fa fa-copy"></i>
          </button>
          <Highlight theme={themes.vsDark} code={match[1]} language={mTypeCode}>
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre style={style}>
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line })}>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
        </div>
      );

      elements.push(codeBlock);
      currentIndex = match.index + match[0].length;
    }

    const remainingText = text.substring(currentIndex).split('\n').map((line, index) => (
      <React.Fragment key={`B${index}`}>
        <span dangerouslySetInnerHTML={{ __html: processText(line) }} />
        <br />
      </React.Fragment>
    ));

    elements.push(...remainingText);
    return <ol>{elements}</ol>;
  };
  
  
  
  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  return (
    <div className="new-modal border-2 border-black fixed inset-0 flex justify-center items-center bg-gray-600 bg-opacity-15">
      <div className={`assistaction-div ${isMaximized ? 'maximized' : ''}`}>


        <button
          onClick={() => {
            handleCloseSearchNav();
            setShowAssist(false);
          }}
          style={{
            position: 'absolute',
            top: isMaximized ? '5px' : '8px',
            right: '7px',
            fontSize: isMaximized ? '20px' : '18px',
            cursor: 'pointer',
            alignItems: 'center',
            transition: 'all 0.3s ease',
          }}
        >
          <FaTimes />
        </button>

        <button
          onClick={handleMaximize}
          style={{
            position: 'absolute',
            top: isMaximized ? '3px' : '4.5px',
            right: '34px',
            fontSize: isMaximized ? '17px' : '15px',
            cursor: 'pointer',
            alignItems: 'center',
            transition: 'all 0.3s ease',
          }}
        >
          <i className="fa-solid fa-expand"></i>
        </button>

        {showAssist && (

<button
  onClick={() => {
    if (selectedConvoId) {
      if (callbackInitiateChat)
         callbackInitiateChat(selectedConvoId);
    } else {
      console.error('No conversation ID found to navigate.');
    }
  }}
  style={{
    position: 'absolute',
    top: isMaximized ? '5px' : '8px',
    left: '7px',
    fontSize: isMaximized ? '20px' : '18px',
    cursor: 'pointer',
    alignItems: 'center',
    transition: 'all 0.3s ease',
  }}
>
  <i className="fa-regular fa-message"></i>
</button>
)
}

       {(isQuestionView && !showAssist) && (
  <div
   
    style={{
      margin: 'auto',
      width: '100%',
      maxWidth: '600px',
      marginTop: isMaximized ? '23px' : '-2em',
    }}
  >
        <div className={`assistmodel-data ${isMaximized ? 'maximized' : ''}`}>

      <h2
        className="Assetmodel text-xl font-semibold mb-4 text-center"
        style={{
          fontSize: isMaximized ? '22px' : '19px',
          textAlign: 'center',
          paddingTop: isMaximized ? '41px' : '48px',
          marginBottom: '1rem', // Add margin below the heading
          transition: 'all 0.3s ease',
        }}
      >
        Perform Action
      </h2>

      <div
        style={{
          padding: '16px', // Adjust padding for better spacing
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '600px',
          height: isMaximized ? '58%' : '100%',
          maxHeight: isMaximized ? '87%' : '100%',
          overflowY: 'auto',
          ...(isMaximized
            ? { paddingTop: '15px', marginBottom: '15px' }
            : { paddingTop: '8px', marginBottom: '10px' }),
        }}
      >
        {loadingState ? (
          <></>
        ) : assetdata?.assist_actions?.length > 0 ? (
          assetdata.assist_actions.map((action) => (
            <button
              key={action.id}
              style={{
                padding: '12px 24px', 
                margin: '10px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s ease',
                width: '80%', 
                maxWidth: '250px', 
                textAlign: 'center',
              }}
              onClick={() => handleShowAssistAction(action.id, action.name)}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#007bff')}
            >
              {action.name}
            </button>
          ))
        ) : (
          <div className="spinner"></div>
        )}
      </div>
    </div>
  </div>
)}



{showAssist && (
 <div
 className={`chat-box bg-gray-100 rounded-lg shadow w-full h-64 flex flex-col mt-3 ${isMaximized ? 'maximized' : ''}`}
>
 <div
   className="chat-messages overflow-y-auto flex-grow mb-3 p-2 rounded-lg"
   ref={chatBoxRef}
 >
   {isFirstInteractionLoading ? (
     <div className="chat-indicator" style={{ top: isMaximized ? '92%' : '88%' }}>
       <span></span>
       <span></span>
       <span></span>
     </div>
   ) : (
     <>
       {chatMessages.map((message, index) => (
         <div
           key={index}
           className={`chat-message ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
           style={{ marginBottom: '8px' }}
         >
           <p
             className={`message-text ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'} inline-block p-2 rounded-lg shadow-sm whitespace-pre-wrap`}
           >
             {insertCodeBlocks(message.text)}
           </p>
         </div>
       ))}

       {loadingState && (
         <div className="chat-indicator text-left" style={{ marginBottom: '8px' }}>
           <span className="bg-gray-300 inline-block w-2 h-2 rounded-full animate-pulse mx-1"></span>
           <span className="bg-gray-300 inline-block w-2 h-2 rounded-full animate-pulse mx-1"></span>
           <span className="bg-gray-300 inline-block w-2 h-2 rounded-full animate-pulse mx-1"></span>
         </div>
       )}
     </>
   )}
 </div>

 <div className="chat-input flex items-center">
   <input
     type="text"
     value={newMessage}
     onChange={(e) => setNewMessage(e.target.value)}
     onKeyDown={(e) => {
       if (e.key === 'Enter') {
         handleSendMessage();
       }
     }}
     placeholder="Type your message..."
     className="flex-1 p-1.5 text-sm border border-gray-300 rounded-l-lg"
     disabled={loadingState || isFirstInteractionLoading}
   />
   <button
     onClick={handleSendMessage}
     className="bg-blue-500 text-white p-4 rounded-r-lg hover:bg-blue-600 transition-colors flex items-center justify-center"
     disabled={loadingState || isFirstInteractionLoading}
   >
     <i className="fa fa-paper-plane" aria-hidden="true"></i>
   </button>
 </div>
</div>

)}


      </div>
    </div>
  );
};

export default AssistAction;
