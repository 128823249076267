// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/loader.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF;EACA;IACE,yBAAyB,EAAE,eAAe;IAC1C,6BAA6B,EAAE,SAAS;IACxC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC","sourcesContent":[".spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #333;\n    border-radius: 50%;\n    width: 24px;\n    height: 24px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  .loader {\n    border: 5px solid #f3f3f3; /* Light grey */\n    border-top: 5px solid #3498db; /* Blue */\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
