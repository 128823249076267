import React, { useState, useEffect } from 'react';
import ApiService from './ApiService';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PageLoader from './components/PageLoader';
import NavBar from './components/NavBar';
import ChatBox from './components/ChatBox';
import LeftMenu from './components/LeftMenu';
import toast, { Toaster } from 'react-hot-toast';
import SearchNav from './pages/SearchNav';
import ToggleSwitch from './components/togglebutton';

const Conversations = ({ match }) => {
  const { id: conversation_id } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggle, setToggle] = useState(screenWidth > 1023);
  const { state } = useLocation();
  const [additionalData, setAdditionalData] = useState(state);
  const additional_data = state || {};
  const [selectedTab, setSelectedTab] = useState('Conversation');
  const [returnSourcesAllowed, setReturnSourcesAllowed] = useState(false);
  const [searchAllowed, setSearchAllowed] = useState(false);

  console.log("additionData: ", additional_data);

  useEffect(() => {

  }, [selectedTab])

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    if (screenWidth < 1024 && toggle) setToggle(false);
  };

  useEffect(() => {

  }, [searchAllowed])
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);

  const callBack = () => {
    setToggle(!toggle);
    console.log('toggle:- ', toggle);
  };

  const [searchOptions, setSearchOptions] = useState([]);
  const [conversationData, setConversationData] = useState(null);
  const [convoId, setConvId] = useState(-1);
  const [convBalanceData, setConvBalanceData] = useState({ balance: -1, customerId: -1 });

  const navigate = useNavigate();
  let { id } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSearchChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log('Selected option:', selectedOption);
  };

  const [paginationNumber, setPaginationNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);

  const fetchData = async () => {
    const x = parseInt(paginationNumber) + 20;
    try {

      const projectResponse = await ApiService.get(`/getproject?project_id=${id}`);
      const projectData = projectResponse.data;

      setReturnSourcesAllowed(projectData.return_sources);
      setSearchAllowed(projectData.search_allowed);

      setIsChatLoading(true);

      const response = await ApiService.get(`/getconversations?skip=${paginationNumber * 20}&limit=${x}&project_id=${id}`);
      setIsChatLoading(false);

      console.log('Response: ', response);

      if (response.detail === 'Conversations not found') setConversationData();
      else setConversationData(response.data);
    } catch (error) {
      toast.error('Unable to process');
      if (error.response && error.response.status === 401) {
        try {
          await ApiService.refresh_tokens();
          const refreshedResponse = await ApiService.get(`/conversation?skip=${paginationNumber * 20}&limit=${x}&project_id=${id}`);
          setIsLoading(false);

          if (refreshedResponse.detail === 'Conversations not found') setConversationData('Conversations not found');
          else setConversationData(refreshedResponse.data);
        } catch (refreshError) {
          toast.error('Unable to process');
          localStorage.setItem('loggedOutFromSystem', 'true');
          setIsLoading(false);
          navigate('/login');
        }
      } else {
        console.log('conversationData: ', conversationData);
        if (error.response && error.response.status === 404 && error?.response?.data?.detail === 'Conversations not found') {
          console.log('error: ', error.response);
          const tcustid = JSON.parse(localStorage.getItem('customerData'));
          console.log('tcustid: ', tcustid);
          setConversationData({
            conversation_id: 'NEW',
            project_id: id,
            status: 'success',
            total_conversations: 0,
            additional_data: additionalData,
            conversations: [],
          });
          setIsChatLoading(false);
        } else {
          navigate(`/projects?noConvo=${id}`);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
    setConvId(-1);
  }, [navigate, paginationNumber]);

  console.log('conversationData: ', conversationData);
  if (!conversationData || isLoading) {
    return <PageLoader />;
  }

  const dataFetched = conversationData.conversations;

  const initiateChat = (id = -1, bal = -1, cid = -1) => {
    handleTabChange('Conversation');
    setConvId(id);
    setConvBalanceData({ balance: bal, customerId: cid });
    setToggle(!toggle);
    setAdditionalData(additionalData);
  };

  console.log('convoId: ', convoId);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <NavBar callback={callBack} showBurgerButton={true} convBalanceData={convBalanceData} />
      <div className="flex flex-row w-full">
        {screenWidth >= 1024 && (
          <div className={`w-full lg:w-72 ${screenWidth < 1024 ? 'hidden' : ''}`}>
            <LeftMenu
              isLoading={isLoading}
              convoId={convoId}
              paginationNumber={paginationNumber}
              setPaginationNumber={setPaginationNumber}
              total_conversations={conversationData?.total_conversations}
              chatData={conversationData?.conversations}
              callback={initiateChat}
              additional_data={additionalData}
            />
          </div>
        )}
        {screenWidth < 1024 && toggle && (
          <LeftMenu
            convoId={convoId}
            isLoading={isLoading}
            paginationNumber={paginationNumber}
            setPaginationNumber={setPaginationNumber}
            total_conversations={conversationData?.total_conversations}
            chatData={conversationData?.conversations}
            callback={initiateChat}
            additional_data={additionalData}
          />
        )}
        {(screenWidth < 1024 && !toggle) || screenWidth >= 1024 ? (
          <div className="w-full flex flex-col lg:w-full special_left_padding">
            {(screenWidth < 1024 && !toggle) || screenWidth >= 1024 ? (
              <div>
                <div>
                  {searchAllowed && convoId !== 'NEW' && conversationData?.conversations?.length === 0 || searchAllowed && (
                    <div className="fixed-center my-20">
                      <ToggleSwitch selectedToggleName={selectedTab} onTabChange={handleTabChange} />
                    </div>
                  )}
                  {searchAllowed && selectedTab === 'Search' && <SearchNav project_id={id} />}
                </div>

                {(convoId.length > 0 || convoId === 'NEW') && (selectedTab !== 'Search') &&
                  (
                    <ChatBox
                      chatsFetched={dataFetched}
                      convoId={convoId}
                      setConvId={setConvId}
                      project_id={id}
                      callback={fetchData}
                      additional_data={additionalData}
                      searchAllowed={searchAllowed}
                      returnSourcesAllowed={returnSourcesAllowed}
                    />
                  )}
                {convoId === -1 && conversationData?.conversations?.length === 0 &&  (
                  <div className="flex justify-center items-center w-full h-screen font-light flex-col">
                    <div className="justify-center flex mx-auto my-4">The conversations list is empty.</div>
                    <div className="flex justify-center mx-auto my-4">
                      <button
                        onClick={() => initiateChat('NEW')}
                        className="py-2 px-8 bg-blue-600 shadow hover:shadow-md cursor-pointer text-white rounded flex flex-row justify-center"
                      >
                        + Start One
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <Toaster />
    </>
  );
};

export default Conversations;