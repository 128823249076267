import React, { useEffect, useState, useRef } from 'react';
import ApiService from '../ApiService';
import { default as Loader } from "../assets/loader.svg";
import Markdown from 'react-markdown'
import toast, { Toaster } from 'react-hot-toast';
import { Highlight, themes } from "prism-react-renderer";
import remarkGfm from 'remark-gfm'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'antd';


const ChatBox = ({ chatsFetched, convoId = 'NEW', project_id = 0, setConvId, callback, additional_data, searchAllowed,returnSourcesAllowed, onMessageIndexClick, openDrawer }) => {
  const containerRef = useRef(null);
  const [loadingState, setLoadingState] = useState(false);
  const [movingState, setMovingState] = useState(false);

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [chatMsg, setChatMsg] = useState('');
  const [sourceFiles, setSourceFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  let columns = additional_data ? Object.keys(additional_data.columns) : null;
  const [values, setValues] = useState(columns !== null && columns.length === 1 ? Object.keys(additional_data['columns'][`${columns[0]}`]['data']) : []);
  const operators = ['LIKE'];
  const [modelData, setModelData] = useState({ field: columns !== null && columns.length === 1 ? columns[0] : '', operator: columns !== null && operators.length === 1 ? operators[0] : '', value: '' });
  const [searchValue, setSearchValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  const formatOptions = (data) => {
    if (data && typeof data === 'object') {
      return Object.keys(data).map(key => ({
        value: data[key],
        label: key
      }));
    }
    return [];
  };

  useEffect(() => {
    if (chatsFetched && chatsFetched.length > 0) {
      const index = chatsFetched[indexSelected]?.conversation_history.length - 1
      setSelectedIndex(index);
      onMessageIndexClick(index);
    }
  }, [chatsFetched, convoId]);

  const handleSelectButtonClick = (index) => {
    setSelectedIndex(index);
    onMessageIndexClick(index);
    openDrawer();
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSelectChange = (event) => {
    handleDialogChange(event.label, event.value);
  };

  useEffect(() => {
    if (sourceFiles.length > 0) {

    }

  }, [sourceFiles]);



  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setModelData({
      field: '', operator: '', value: ''
    })
    setShowDialog(false);
  };

  const discardDialog = () => {
    setModelData({
      field: columns.length === 1 ? columns[0] : '',
      operator: operators.length === 1 ? operators[0] : '',
      value: ''
    });
    if (columns.length !== 1) setValues();

    setShowDialog(false);
    setIsShowDialog(false);
  };

  const handleDialogChange = (name, value) => {


    setModelData({ ...modelData, value: String(value) });
  };
  const handleDialogChangeRegular = (e) => {
    const { name, value } = e.target;
    if (value != "") {
      if (name === 'field') {
        const keys = Object.keys(additional_data['columns'][`${value}`]['data']);
        setValues(keys);
      }
      setModelData({ ...modelData, [name]: value });
    } else {
      if (name === 'field') {
        setValues();
      }
      setModelData({ ...modelData, [name]: value });
    }
  };

  const [showAllFiles, setShowAllFiles] = useState(false);

  const handleLoadMore = () => {
    setShowAllFiles(true);
  };

  const handleToggleFiles = (index) => {
    setShowAllFiles(!showAllFiles)
  };
  const handleDialogSubmit = (e) => {
    console.log("model data::;", modelData)
    e.preventDefault();
    setShowDialog(false);
    if (modelData.field && modelData.operator && modelData.value) {
      setIsShowDialog(true)
    }
  };

  const emptyField = () => toast('Message field is empty.', {
    style: {
      backgroundColor: '#e23650',
      color: '#ffffff',
    },
  });

  const textCopied = () => toast('Text copied to clipboard.', {
    style: {
      backgroundColor: '#333333',
      color: '#ffffff',
    },
  });

  const openLinksInNewWindows = () => {
    const container = document.getElementsByClassName('messages__box')[0];

    if (container) {
      const links = container.getElementsByTagName('a');

      for (let link of links) {
        link.target = '_blank';
        link.onclick = function (event) {
          event.preventDefault();
          window.open(link.href, '_blank');
        };
      }
    } else {
      console.warn(`Element not found.`);
    }
  };

  useEffect(() => {
    const el = document.getElementsByTagName('textarea')[0];
    if (el) {
      el.style.height = '40px'; // Reset height
      el.style.height = `${el.scrollHeight}px`; // Set new height
    }
  }, [newMessage]);

  useEffect(() => {
    openLinksInNewWindows();
    setChatMsg('');
  }, [chatsFetched]);
  useEffect(() => {
    if (convoId !== "NEW" && chatsFetched[indexSelected]?.additional_data != null) {
      const additionalWhereQueries = chatsFetched[indexSelected].additional_data.additional_where_queries;

      if (additionalWhereQueries && additionalWhereQueries.length > 0) {
        let selectedColumn;
        let selectedValue;
        let operator = additionalWhereQueries[0].operator;
        if (additional_data) {
          for (let column in additional_data['columns']) {
            if (additional_data['columns'][column]['backend_identifier'] === additionalWhereQueries[0].field) {
              selectedColumn = column;
            }
          }
          selectedValue = Object.entries(additional_data['columns'][selectedColumn]['data']).find(([key, value]) => value == additionalWhereQueries[0].value)?.[0];
        }

        setModelData((prevData) => ({
          ...prevData,
          field: selectedColumn,
          value: selectedValue,
          operator: operator
        }));

        if (selectedColumn && operator && selectedValue) {
          setIsShowDialog(true);
        } else {
          setIsShowDialog(false);
        }
      } else {
        setIsShowDialog(false);
      }
    } else {
      // Clear modelData when convoId is "NEW" or additional_data is not available
      setModelData((prevData) => ({
        ...prevData,
        value: '',
      }));
      setIsShowDialog(false);
    }

    setTimeout(() => scrollToBottom(false), 100);
  }, [chatsFetched, convoId, additional_data, setModelData]);


  const scrollToBottom = (smooth = true) => {
    if (containerRef.current) {
      if (smooth) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
      else containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    setTimeout(() => { scrollToBottom(false); }, 100);
  }, [chatsFetched, convoId]);

  const findElementIndexByProperty = (data, property, value) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i][property] === value) {
        return i;
      }
    }
    return -1;
  };

  const indexSelected = findElementIndexByProperty(chatsFetched, "id", convoId);

  const carouselRef = [{}]
  const [selectedCarousel, setSelectedCarousel] = useState(0);

  const [slideAction, setSlideAction] = useState(null);
  if (Array.isArray(chatsFetched) && chatsFetched[indexSelected] && Array.isArray(chatsFetched[indexSelected].conversation_history)) {
    for (let i = 0; i < chatsFetched[indexSelected].conversation_history.length; i++) {
      if (!carouselRef[i]) {
        carouselRef[i] = React.createRef();
      }
    }
  } else {
    console.error("chatsFetched or conversation_history is undefined or not an array");
  }



  useEffect(() => {
    if (slideAction === 'next') {
      carouselRef[selectedCarousel]?.current?.next();
    } else if (slideAction === 'prev') {
      carouselRef[selectedCarousel]?.current?.prev();
    }
    setSlideAction(null);
  }, [selectedCarousel, slideAction]);

  const handleNext = () => {
    setSlideAction('next');
  };

  const handlePrev = () => {
    setSlideAction('prev');
  };

  const [kindex, setKindex] = useState([0]);

  const handleBeforChange = (current, next, i) => {
    const newIndexes = [...kindex];
    newIndexes[i] = next;
    setKindex(newIndexes);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setNewMessage(value);
  };

  const processOpeningNewChat = (response) => {
    console.log('response: ', response?.data?.conversation_id);
    if (convoId === 'NEW') {
      if (response?.data?.conversation_id && setConvId) {
        setConvId(response?.data?.conversation_id);
      }
    }
    if (response?.data?.source_files != null) {
      setSourceFiles(response?.data?.source_files)
    }

  };


  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage?.length < 1) { emptyField(); return; }
    // condition for the compulsory additional data
    // if ((modelData.field == '' && additional_data != null) || (modelData.value === '' && additional_data !== null)) {
    //   toast.error('Please select Additional data');
    //   return;
    // }

    console.log(sourceFiles)
    const value = additional_data != null ? Object.entries(Object.entries(additional_data.columns)?.[0][1].data).find(val => val[0] == modelData.value) : modelData.value;
    const fieldValue = modelData.field;
    modelData.value = modelData.value != '' ? `${value?.[1]}` : ""
    modelData.field = additional_data != null ? (modelData.field != '' ? additional_data['columns'][`${modelData.field}`]['backend_identifier'] : "") : modelData.field
    e.preventDefault();
    console.log("modelData::::", JSON.stringify(modelData))
    setLoadingState(true);
    const formData = {
      "conversation_id": convoId === 'NEW' ? null : convoId,
      "question": newMessage,
      "project_id": project_id,
    }

    if (modelData.field && modelData.operator && modelData.value) {
      formData["additional_data"] = JSON.stringify({ additional_where_queries: [modelData] });
    };
    modelData.value = modelData.value != '' ? `${value?.[0]}` : ""
    modelData.field = fieldValue;
    if (!newMessage)
      setTimeout(() => scrollToBottom(), 500);

    setChatMsg(newMessage);
    setNewMessage('');
    try {

      const response = await ApiService.post('/answer', formData);
      console.log('Response:', response);

      processOpeningNewChat(response);
      const el = document.getElementsByTagName('textarea')[0];
      if (el) {
        el.style.height = "40px";
      }
      if (callback) callback();
    } catch (error) {
      toast.error('Unable to process');
      setMovingState(false);
      console.error('Error:', error);
      const dynamicErrorToast = () => toast(error?.response?.detail || error?.response?.data || 'Unknown Error. Try again.', {
        style: {
          backgroundColor: '#e23650',
          color: '#ffffff',
        },
      });
      dynamicErrorToast();
    } finally {

      setLoadingState(false);
    }
  };

  const CopyToClipboard = (t) => {
    navigator.clipboard.writeText(t).then(() => {
      textCopied();
    }).catch(err => {
    });
  };
  const insertCodeBlocks = (text) => {
    const regex = /```([\s\S]*?)```/g;
    let match;
    let currentIndex = 0;
    const elements = [];
    const processText = (line) => {
      const headingRegex = /^(#{1,6})\s+(.*)/;

      const boldItalicRegex = /(\*\*\*|___)(.*?)\1/g;
      const boldRegex = /(\*\*|__)(.*?)\1/g;
      const italicRegex = /(\*|_)(.*?)\1/g;

      const headingMatch = line.match(headingRegex);
      if (headingMatch) {
        const headingLevel = headingMatch[1].length; 
        const headingText = headingMatch[2];
        const formattedHeadingText = headingText
          .replace(boldItalicRegex, (_, __, match) => `<b><em>${match}</em></b>`)
          .replace(boldRegex, (_, __, match) => `<b>${match}</b>`)
          .replace(italicRegex, (_, __, match) => `<em>${match}</em>`);
        return `<h${headingLevel}>${formattedHeadingText}</h${headingLevel}>`;
      }

      line = line.replace(boldItalicRegex, (_, __, match) => `<b><em>${match}</em></b>`);

      line = line.replace(boldRegex, (_, __, match) => `<b>${match}</b>`);

      line = line.replace(italicRegex, (_, __, match) => `<em>${match}</em>`);

      return line;
    };


    while ((match = regex.exec(text)) !== null) {
      const beforeText = text.substring(currentIndex, match.index).split('\n').map((line, index) => (
        <React.Fragment key={`A${index}`}>
          <span dangerouslySetInnerHTML={{ __html: processText(line) }} />
          <br />
        </React.Fragment>
      ));
      elements.push(...beforeText);

      const x2 = match[1];
      const mType = x2.split('\n')[0];
      let mTypeCode = 'jsx';
      switch (mType.trim()) {
        case 'javascript':
          mTypeCode = 'js';
          break;
        case 'typescript':
          mTypeCode = 'tsx';
          break;
        case 'c++':
          mTypeCode = 'cpp';
          break;
        case 'php':
          mTypeCode = 'javascript';
          break;
        case 'asp':
          mTypeCode = 'javascript';
          break;
        case 'csharp':
          mTypeCode = 'cpp';
          break;
        default:
          mTypeCode = mType.toLowerCase();
          break;
      }
      const codeBlock = (
        <div className="code-block">
          <button
            onClick={() => CopyToClipboard(x2)}
            title="Copy Code"
            className="flex justify-end p-1 mr-0 ml-auto right-0 rounded text-xs text-gray-500 hover:bg-gray-500 hover:text-gray-100 duration-300"
          >
            <i className="fa fa-copy"></i>
          </button>
          <Highlight theme={themes.vsDark} code={match[1]} language={mTypeCode}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre style={style}>
                {tokens.map((line, i) => (
                  <div key={i} {...getLineProps({ line })}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </div>
                ))}
              </pre>
            )}
          </Highlight>
        </div>
      );

      elements.push(codeBlock);
      currentIndex = match.index + match[0].length;
    }

    const remainingText = text.substring(currentIndex).split('\n').map((line, index) => (
      <React.Fragment key={`B${index}`}>
        <span dangerouslySetInnerHTML={{ __html: processText(line) }} />
        <br />
      </React.Fragment>
    ));

    elements.push(...remainingText);
    return <ol>{elements}</ol>;
  };



  const handleKeyPress = (event) => {
    event.preventDefault();
    if (event.key === 'Enter' && !loadingState && window.location.href.includes('/conversations')) {
      if (event.shiftKey) {
        console.log('Shift + Enter was pressed');
      } else {
        sendMessage(event);
        setTimeout(() => scrollToBottom(), 500);
      }
    }

    const el = event.currentTarget;
    el.style.height = "40px";
    el.style.height = (el.scrollHeight) + "px";
  };

  const convertNewlinesToBreaks = (text) => {
    return text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/### (.*?)\n?/g, '<h3>$1</h3>\n')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Bold first
      .replace(/\*(.*?)\*/g, '<em>$1</em>')  // Italic next
      .replace(/_(.*?)_/g, '<em>$1</em>')  // Italic with underscores
      .replace(/\n/g, '<br>');
  };


  const preventEnter = (event) => {
    if (event.key === 'Enter') {
      if (!event.shiftKey)
        event.preventDefault();
    }
  };

  return (
    <div className="w-full h-auto text-start text-left messages__box">



      <div className="flex flex-col flex-grow w-full  bg-white overflow-hidden" style={{ height: searchAllowed == true ? '80vh' : '100vh' }}>

        <div className="flex flex-col flex-grow h-0 p-4 w-full overflow-auto" ref={containerRef}>
          <div className="my-8">&nbsp;

          </div>
          <div className="chat-container mx-auto" style={{ maxWidth: '1200px' }}>
            {convoId === 'NEW' && !loadingState && <div className="flex w-full my-6 mx-auto justify-center h-full text-thin text-lg text-gray-600"> Type a question to start conversation.</div>}
            {chatsFetched.length > 0 && convoId !== 'NEW' && chatsFetched[indexSelected]?.conversation_history?.map((el, i) => {
              return <>

                {el[0]?.data?.type === 'human' &&
                  <div className="flex w-full my-6 space-x-1 md:space-x-3 max-w-3xl ml-auto justify-end" > <div>
                    <div style={{padding: '1rem'}} className="bg-blue-500 text-white p-4 rounded-l-xl rounded-br-xl convo_box">
                      <div className="text-sm md:text-lg"><button onClick={() => CopyToClipboard(el[0]?.data?.content)} title="Copy" className="flex hidden justify-end  p-1 mr-0 ml-auto right-0 rounded text-xs text-white hover:bg-blue-500 hover:text-blue-100 duration-300"><i className="fa fa-copy"></i></button>

                        <div dangerouslySetInnerHTML={{ __html: convertNewlinesToBreaks(el[0]?.data?.content) }} /></div>
                    </div>
                    <span className="text-xs text-gray-500 leading-none hidden">0 min ago</span>
                  </div>
                    <div className="hidden md:flex flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 flex text-center items-center text-white justify-center">Me</div>

                  </div>
                }
                {el[1]?.data?.type === 'ai' && (
                  <div className="flex w-full my-6 space-x-1 md:space-x-3 max-w-3xl" style={{ position: "relative" }}>
                    <div className="hidden md:flex flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 text-center items-center text-white justify-center">
                      AI
                    </div>
                    <div className="flex-1">
                      <div style={{padding: '1rem'}} className={`bg-gray-200 p-4 rounded-r-xl rounded-bl-xl convo_box ${selectedIndex === i ? 'glow-effect' : ''}`}>
                        <p className="text-sm md:text-lg">
                          <button
                            onClick={() => CopyToClipboard(el[1]?.data?.content)}
                            title="Copy"
                            className="flex justify-end p-1 mr-0 ml-auto right-0 rounded text-xs text-gray-400 hover:bg-gray-400 hover:text-gray-100 duration-300"
                          >
                            <i className="fa fa-copy"></i>
                          </button>
                          {insertCodeBlocks(el[1]?.data?.content)}
                        </p>

                        {returnSourcesAllowed && el[3]?.length > 0 && (
                          <>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <button
                                style={{ margin: '1rem 0' }}
                                className={`btn-outline btn-outline-dark btn-sm ${selectedIndex === i ? 'border-2 border-blue-500' : ''}`} // Conditional border based on selectedIndex
                                key={i}
                                onClick={() => handleSelectButtonClick(i)} // Use the new click handler
                              >
                                Show Source Files &gt;&gt;
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <span className="text-xs text-gray-500 leading-none hidden">0 min ago</span>
                  </div>
                )}
              </>;
            })}

            {chatMsg.length > 0 &&
              <div className="flex w-full my-6 space-x-1 md:space-x-3 max-w-3xl ml-auto justify-end z-20"> <div>
                <div className="bg-blue-500 text-white p-4 rounded-l-xl rounded-br-xl convo_box">
                  <div className="text-sm md:text-lg">


                    <div dangerouslySetInnerHTML={{ __html: convertNewlinesToBreaks(chatMsg) }} /></div>
                </div>
                <span className="text-xs text-gray-500 leading-none hidden">0 min ago</span>
              </div>
                <div className="hidden md:flex flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 flex text-center items-center text-white justify-center">Me</div>

              </div>
            }
            {loadingState &&
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          </div>
        </div>
        <div className="bg-white-300 p-4 pb-3 flex flex-col">
          <div>
            {isShowDialog && (
              <div
                className="md:bottom-10 md:right-16 text-white rounded-full p-1"
                style={{
                  position: "sticky",
                  bottom: "-1rem",
                  textAlign: "left",
                  overflow: "hidden",
                  fontSize: "0.875rem",
                  color: "#3a83f6",
                  backgroundColor: "#dbe9ff",
                  border: "1px solid black",
                  display: "inline-block",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                }}
              >
                <div className="flex items-center gap-1">
                  <div className="flex-1 text-center" style={{ margin: "0 0.5rem" }}>
                    Field: {modelData.field}
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="flex-1 text-center" style={{ margin: "0 0.5rem" }}>

                      Operator: {modelData.operator}
                    </div>
                    <div className="flex items-center gap-1">

                      Value: {modelData.value}
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
          <div className='bg-white-300 p-4 pb-3 flex'>

            <div className='mr-2'>
              {additional_data && (
                <button
                  title="Add New Entry"
                  tabIndex="0"
                  disabled={movingState}
                  className={`${movingState ? 'bg-gray-100 text-gray-300' : 'bg-white'} border border-gray-300 px-4 text-gray-600 rounded h-10 flex items-center justify-center`}
                  onClick={openDialog}
                >
                  <i className="fa fa-plus text-gray-600"></i>
                </button>
              )}
            </div>

            <Toaster />

            <textarea
              onKeyUp={handleKeyPress}
              onKeyDown={preventEnter}
              disabled={movingState}
              value={newMessage}
              className="flex h-10 w-full items-center w-full border-gray-300 border rounded px-3 text-sm md:text-lg focus:outline-none focus:ring-1 focus:ring-blue-200 box-border"
              onChange={handleChange}
              type="text"
              placeholder="Type your message…"
            />

            <button
              title="Send"
              tabIndex="0"
              disabled={movingState}
              className={`${movingState ? 'bg-gray-100 text-gray-300' : 'bg-white'} border border-gray-300 px-4 text-gray-600 rounded  h-10 flex items-center justify-center`}
              onClick={sendMessage}
            >
              {movingState && <img src={Loader} alt="loading" />}
              {!movingState && <i className="fa-regular fa-paper-plane text-gray-600" />}
            </button>
          </div>
        </div>

        <div className="w-full text-center text-xs text-gray-600 pb-2">
          <p>Pixo may make mistakes. Please verify important information.</p>
        </div>
        {showDialog && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 p-4">
            <div className="bg-white p-6 rounded-md w-full max-w-lg mx-auto">
              <h2 className="text-lg font-bold mb-4">Advanced Query</h2>
              <form onSubmit={handleDialogSubmit}>
                <div className="mb-4 border border-gray-300 rounded-md p-4">
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Column</label>
                    <select
                      name="field"
                      value={modelData.field || (columns.length === 1 ? columns[0] : "")}
                      onChange={handleDialogChangeRegular}
                      className="border p-2 w-full rounded-md"
                      disabled={!columns || columns.length === 0}
                    >

                      <option value="">
                        {columns && columns.map((col, idx) => (
                          <option key={idx} value={col}>
                            {col}
                          </option>
                        ))}
                      </option>
                    </select>

                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Operator</label>
                    <select
                      name="operator"
                      value={modelData.operator || (operators.length === 1 ? operators[0] : "")}
                      onChange={handleDialogChangeRegular}
                      className="border p-2 w-full rounded-md"
                      disabled={!operators || operators.length === 0}
                    >
                      <option value="">
                        {operators && operators.map((op, idx) => (
                          <option key={idx} value={op}>
                            {op}
                          </option>
                        ))}
                      </option>
                    </select>

                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Value</label>
                    <Select
                      name="value"
                      value={modelData.value ? { value: modelData.value, label: modelData.value } : null}
                      onChange={(selectedOption) =>
                        handleDialogChangeRegular({
                          target: { name: 'value', value: selectedOption ? selectedOption.value : '' }
                        })
                      }
                      options={values.map((val) => ({ value: val, label: val }))}
                      className="border p-2 w-full rounded-md"
                      isDisabled={!values || values.length === 0}
                      placeholder="Select Value"
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white p-2 rounded-md mr-2"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={discardDialog}
                    className="bg-gray-500 text-white p-2 rounded-md"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBox;