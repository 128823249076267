/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { default as Logo } from "../assets/logo.png";
import { default as downArrow } from "../assets/dropdown.svg";
import './PageLoader.css'; // Styles for the loader
import { Link } from 'react-router-dom';
import ApiService from '../ApiService';
import toast, { Toaster } from 'react-hot-toast';

const NavBar = ({ balance = -1, callback, showBurgerButton = false, convBalanceData }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const [customerData, setCustomerData] = useState(null);

    const boxShadowStyle = window.innerWidth >= 1024 ? 'none' : '';
    

  
    useEffect(() => {
      const fetchData = async () => {
        if(true) {
        try {
          const response = await ApiService.get('/users/me');
          setCustomerData(response.data);
          localStorage.setItem('customerData', JSON.stringify(response?.data));
        } catch (error) {
          toast.error('Unable to process');
          if (error.response && error.response.status === 401) {
            // Access token expired, try refreshing tokens
            try {
              await ApiService.refresh_tokens();
              // Retry fetching dashboard data
              const refreshedResponse = await ApiService.get('/users/me');
              setCustomerData(refreshedResponse.data);
              localStorage.setItem('customerData', JSON.stringify(refreshedResponse?.data));
            } catch (refreshError) {
            }
          } else {
            // Other API error, handle accordingly
          }
        }
      }
      };
  
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convBalanceData, isMenuOpen]);
  
   
  
    const dataFetched = customerData;

  return (
    <>
      <Toaster/>
      <nav style={{ zIndex: '1', boxShadow: boxShadowStyle }} className="fixed flex flex-nowrap items-center justify-between w-full py-1 md:py-0 px-4 text-lg text-gray-700 bg-white border-b border-gray-200 shadow">
         <div className="flex">
            <a href="/projects">
              <img src={Logo} className="logo_navbar" alt="Pixo" />
            </a>
            
            {showBurgerButton && 
              <div className="flex lg:hidden ml-4 mt-1">
                <button className="hover:bg-gray-100 py-1 px-2 rounded-md" onClick={callback}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>
            }
          </div>
{/*          
           <svg
              xmlns="http://www.w3.org/2000/svg"
              id="menu-button"
              className="h-6 w-6 cursor-pointer md:hidden block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg> */}
         
         <div className="min-w-128" id="menu">
            {/* <ul
              className="
                pt-4
                text-base text-gray-700
                md:flex
                md:justify-between 
                md:pt-0"
            >
              <li>
                <a className="md:p-4 py-2 block hover:text-purple-400" href="#"
                  >Features</a
                >
              </li>
              <li>
                <a className="md:p-4 py-2 block hover:text-purple-400" href="#"
                  >Pricing</a
                >
              </li>
              <li>
                <a className="md:p-4 py-2 block hover:text-purple-400" href="#"
                  >Customers</a
                >
              </li>
              <li>
                <a className="md:p-4 py-2 block hover:text-purple-400" href="#"
                  >Blog</a
                >
              </li>
              <li>
                <a
                  className="md:p-4 py-2 block hover:text-purple-400 text-purple-500"
                  href="#"
                  >Sign Up</a
                >
              </li>
            </ul> */}
            {balance > -1 && 
          <div className="lg:ml-4 lg:mr-auto lg:justify-start ml-auto mr-4 justify-end mt-1 text-gray-700 align-end group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"></path></svg>{balance || 0}</div>}
             <div style={{position: 'relative'}}>
              <button className="bg-white-700 hover:bg-gray-100 text-gray-600 font-medium rounded-lg text-sm px-4 py-2 my-0 md:my-1 text-center inline-flex items-center" type="button" onClick={() => setIsMenuOpen(!isMenuOpen)} data-dropdown-toggle="dropdown">
                <span className="rounded-full w-12 h-12 md:w-12 md:h-12 text-white flex items-center justify-center text-4xl md:text-5xl text-center mr-2 text-gray-200">
                  <img 
                    src={dataFetched?.image ? dataFetched.image : "https://randomuser.me/api/portraits/men/1.jpg"} 
                    className="rounded-full" 
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    alt="User" 
                  />
                </span> 
                <img 
                  src={downArrow} 
                  className={`${isMenuOpen ? 'rotate-180' : ''}`} 
                  style={{ fontSize: '12px', width: '28px' }} 
                  alt="More" 
                />
              </button>
             </div>

<div style={{ minWidth: '180px' }} className={` ${!isMenuOpen ? 'hidden':'block'} transition-all absolute top-13 right-5 bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow-md my-4`} id="dropdown">
    <div className="px-4 py-3">
    {/* {!dataFetched?.firstname  &&  <span className="skeleton-box h-5 w-4/5 inline-block mb-1"></span>} */}
    {!dataFetched?.email  &&  <span className="skeleton-box h-5 w-3/5 inline-block mb-5"></span>}
    <span className="block text-md truncate">{dataFetched?.email || '' } </span>
    <span className="block text-sm text-bold mb-4 text-blue-300 truncate">{dataFetched?.username || '' } </span>
    {/* {(!dataFetched?.balance || dataFetched?.balance === 0) &&  <span className="skeleton-box h-5 w-4/5 inline-block"></span>} */}
    {dataFetched?.balance >= 0 &&
    <span className="block text-sm font-medium text-gray-900 truncate"><b>Balance:</b> {process.env.REACT_APP_CURRENCY_SYMBOL}{dataFetched?.balance?.toFixed(2) || '0'}</span>}
    {/* {!customerData &&
    <span className="block text-sm font-medium text-gray-900 truncate"><b>Balance</b> {}</span>} */}
    </div>
    <ul className="py-1" aria-labelledby="dropdown">
    <li>
      <Link to="/logout"><span  className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Sign out</span></Link>
    </li>
    </ul>
    <ul className="py-1" aria-labelledby="dropdown">
    <li>
      <a href="https://web.pixoai.ch/forgot-password " className="text-gray-600  hover:text-gray-800">
        <span className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Change Password</span>
      </a>
    </li>
    </ul>
    <ul className="py-1" aria-labelledby="dropdown">
    <li>
      <Link to='https://www.pixoai.ch/support/' target="_blank"><span className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Support</span></Link>
    </li>
    </ul>
    <ul className="py-1" aria-labelledby="dropdown">
    <li>
      <Link to='https://www.pixoai.ch/changelog/' target="_blank"><span  className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">ChangeLog</span></Link>
    </li>
    </ul>
</div>
          </div>
      </nav>
    </>
  );
};

export default NavBar;